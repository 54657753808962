import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../layouts"
import { SEO } from "../components"
import { Box } from "../styles"
import { motion } from "framer-motion"

const Content = styled(motion.div)`
  padding: 70px;
  width: clamp(370px, 70vw, 80em);
  background: white;
  border-radius: 30px 0 30px 0;
  line-height: 1.9em;
  border: 4px solid var(--secondcolor);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 8px 16px rgba(0, 0, 0, 0.16);
  display: grid;
  grid-gap: 10px;
  padding-top: 75px;

  & h2 {
    margin-bottom: 30px;
    max-width: 30em;
  }

  & h3 {
    max-width: 40em;
  }

  & .body {
    line-height: 2.6em;
    max-width: 60em;
    justify-self: center;
  }

  & .btn {
    border: 2px solid var(--primary);
    width: auto;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    color: var(--primary);
    justify-self: flex-end;
    margin-top: 40px;
    transition: color 300ms ease, background-color 300ms ease;

    &:hover {
      color: white;
      background: var(--primary);
    }
  }

  @media screen and (max-width: 1550px) {
    width: clamp(370px, 90vw, 80em);
  }

  @media screen and (max-width: 760px) {
    padding: 40px;
    margin: 200px 5px;

    & h2 {
      max-width: 20em;
    }

    .body {
      line-height: 2em;
    }
  }
`

const Grid = styled.div`
  display: grid;

  margin: 100px 0;
  place-content: center;

  @media screen and (max-width: 760px) {
    margin: -100px 0;
  }
`

const Conferences = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark

  return (
    <>
      <SEO description={frontmatter.detail} title={frontmatter.title} />
      <Layout>
        <Box />
        <Grid>
          <Content
            animate={{
              y: 0,
              opacity: 1,
            }}
            initial={{ y: 100, opacity: 0 }}
          >
            <h2>{frontmatter.title}</h2>
            <hr />
            <div>
              <h3>{frontmatter.detail}</h3>
              <p>{frontmatter.location}</p>
              <p>{frontmatter.author}</p>
              <p>{frontmatter.date}</p>
            </div>

            <p className="body" dangerouslySetInnerHTML={{ __html: html }} />

            {frontmatter.download && (
              <a className="btn" download href={frontmatter.download}>
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 20"
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  ></path>
                </svg>{" "}
                Download full document
              </a>
            )}
          </Content>
        </Grid>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        detail
        location
        author
        date
        download
      }
    }
  }
`

export default Conferences
